export default {
  primes: [
    [
      145, 81, 247, 158, 61, 18, 219, 202, 232, 226, 212, 174, 111, 182, 150, 2, 124, 131, 141, 217,
      20, 249, 196, 189, 199, 196, 207, 95, 5, 52, 165, 181, 243, 87, 37, 128, 31, 187, 107, 38, 34,
      134, 129, 167, 201, 71, 221, 179, 234, 206, 184, 156, 159, 153, 69, 255, 92, 94, 216, 92, 123,
      213, 75, 229, 157, 100, 42, 138, 122, 46, 115, 197, 82, 150, 22, 65, 122, 143, 9, 1, 9, 34,
      18, 10, 239, 223, 17, 113, 111, 203, 244, 74, 115, 61, 18, 215, 18, 168, 123, 39, 236, 18,
      244, 253, 181, 111, 215, 221, 106, 188, 21, 6, 44, 91, 150, 49, 151, 218, 39, 73, 254, 108,
      160, 249, 81, 35, 165, 67,
    ],
    [
      160, 201, 94, 179, 175, 189, 116, 18, 172, 229, 106, 138, 0, 75, 194, 93, 46, 66, 4, 220, 237,
      4, 40, 216, 187, 196, 94, 119, 153, 204, 25, 125, 224, 237, 174, 72, 105, 12, 164, 64, 108,
      122, 38, 116, 58, 9, 101, 103, 184, 112, 208, 70, 107, 80, 161, 180, 213, 87, 108, 189, 123,
      221, 135, 251, 163, 238, 234, 217, 135, 182, 103, 105, 216, 82, 20, 162, 63, 11, 86, 183, 46,
      56, 10, 4, 77, 163, 153, 141, 174, 222, 1, 111, 121, 91, 188, 140, 208, 247, 20, 232, 154,
      151, 255, 157, 161, 37, 0, 49, 36, 173, 221, 150, 128, 66, 135, 233, 234, 78, 92, 47, 137,
      210, 218, 112, 64, 253, 99, 243,
    ],
    [
      176, 84, 120, 240, 56, 189, 110, 65, 46, 106, 196, 122, 21, 201, 59, 145, 13, 87, 33, 112,
      237, 32, 19, 246, 214, 80, 73, 247, 91, 89, 13, 37, 50, 137, 247, 62, 221, 59, 114, 212, 37,
      42, 243, 115, 191, 66, 16, 202, 247, 55, 220, 240, 47, 123, 149, 134, 75, 22, 3, 6, 252, 230,
      78, 163, 111, 74, 250, 254, 138, 149, 70, 23, 108, 124, 158, 73, 146, 20, 211, 8, 195, 138,
      216, 64, 232, 18, 153, 21, 227, 178, 255, 231, 61, 100, 237, 5, 101, 235, 165, 190, 230, 43,
      143, 106, 135, 4, 193, 68, 71, 213, 90, 39, 111, 64, 149, 14, 214, 66, 223, 87, 241, 66, 103,
      73, 139, 53, 135, 195,
    ],
    [
      142, 53, 84, 26, 33, 215, 174, 82, 178, 158, 65, 41, 36, 152, 127, 139, 197, 84, 90, 109, 103,
      78, 94, 198, 149, 47, 225, 230, 115, 130, 194, 200, 81, 168, 101, 114, 98, 61, 177, 75, 5,
      177, 145, 221, 227, 162, 65, 164, 108, 175, 141, 135, 195, 231, 15, 60, 128, 194, 133, 208,
      69, 128, 254, 215, 114, 154, 198, 158, 109, 213, 187, 214, 158, 249, 206, 122, 105, 179, 103,
      3, 182, 125, 47, 178, 49, 40, 174, 108, 200, 234, 147, 92, 166, 82, 149, 188, 194, 204, 56,
      232, 83, 74, 155, 128, 101, 255, 174, 173, 116, 143, 235, 160, 156, 12, 125, 136, 25, 12, 107,
      22, 160, 16, 138, 212, 164, 236, 224, 235,
    ],
    [
      240, 159, 188, 106, 178, 194, 69, 20, 78, 188, 78, 21, 110, 183, 211, 184, 115, 101, 111, 40,
      190, 164, 200, 202, 89, 171, 238, 27, 225, 151, 72, 244, 45, 57, 113, 53, 19, 21, 166, 255,
      104, 157, 213, 137, 116, 135, 176, 59, 199, 9, 227, 100, 64, 75, 30, 151, 174, 105, 181, 137,
      189, 128, 248, 217, 10, 22, 223, 107, 116, 243, 45, 64, 226, 151, 29, 48, 240, 172, 100, 81,
      43, 200, 23, 67, 31, 223, 56, 169, 62, 8, 212, 90, 144, 244, 126, 106, 46, 235, 37, 142, 132,
      238, 203, 28, 32, 120, 58, 230, 136, 227, 52, 12, 66, 71, 182, 37, 174, 58, 93, 218, 94, 251,
      213, 25, 56, 109, 163, 11,
    ],
    [
      246, 25, 62, 57, 3, 211, 201, 60, 45, 201, 150, 1, 190, 238, 76, 102, 74, 251, 46, 148, 77,
      29, 170, 58, 244, 105, 8, 98, 210, 151, 133, 169, 246, 23, 82, 120, 229, 35, 147, 74, 213, 4,
      72, 145, 136, 233, 32, 137, 37, 224, 243, 5, 139, 4, 160, 166, 149, 244, 212, 219, 224, 247,
      214, 181, 167, 166, 108, 86, 34, 111, 18, 209, 99, 87, 0, 176, 210, 79, 16, 216, 191, 44, 55,
      132, 214, 25, 245, 124, 98, 228, 240, 133, 112, 89, 169, 47, 151, 247, 250, 57, 221, 161, 14,
      109, 147, 162, 179, 95, 51, 145, 147, 18, 195, 48, 190, 74, 88, 26, 132, 25, 66, 236, 103,
      148, 101, 136, 234, 195,
    ],
    [
      188, 223, 9, 149, 64, 85, 250, 187, 46, 43, 7, 141, 98, 242, 217, 92, 165, 224, 181, 233, 215,
      8, 78, 218, 132, 92, 205, 217, 68, 104, 156, 149, 203, 154, 98, 135, 113, 216, 27, 127, 182,
      178, 20, 77, 55, 90, 35, 34, 177, 253, 33, 129, 91, 152, 221, 91, 175, 206, 119, 211, 228,
      153, 125, 219, 231, 255, 207, 84, 23, 31, 124, 216, 95, 207, 228, 144, 74, 114, 60, 44, 139,
      192, 167, 89, 9, 227, 100, 155, 200, 81, 121, 8, 33, 189, 116, 50, 127, 62, 60, 127, 250, 195,
      25, 252, 112, 172, 137, 183, 213, 2, 207, 49, 161, 185, 65, 211, 21, 44, 233, 188, 143, 13,
      44, 78, 62, 78, 234, 115,
    ],
    [
      230, 27, 59, 105, 243, 69, 171, 168, 131, 232, 236, 243, 79, 126, 36, 61, 196, 211, 165, 189,
      11, 97, 219, 28, 184, 125, 124, 87, 187, 235, 98, 133, 25, 243, 79, 9, 45, 21, 88, 231, 50,
      238, 150, 58, 26, 52, 98, 177, 199, 72, 155, 255, 177, 165, 204, 130, 184, 191, 171, 70, 63,
      249, 144, 234, 97, 91, 88, 155, 116, 120, 46, 149, 102, 181, 161, 146, 72, 138, 88, 17, 224,
      53, 56, 188, 167, 108, 50, 220, 16, 195, 67, 248, 221, 43, 208, 223, 236, 97, 85, 200, 145,
      125, 71, 18, 161, 242, 196, 210, 243, 167, 159, 216, 11, 36, 0, 115, 232, 210, 227, 230, 216,
      2, 210, 29, 123, 77, 122, 11,
    ],
    [
      148, 187, 155, 90, 57, 66, 144, 3, 154, 113, 206, 8, 135, 246, 49, 190, 183, 47, 52, 148, 8,
      73, 234, 204, 210, 211, 80, 234, 245, 125, 69, 247, 156, 15, 20, 218, 136, 226, 167, 14, 47,
      135, 101, 213, 192, 25, 237, 113, 187, 103, 7, 28, 249, 119, 213, 91, 251, 132, 152, 74, 168,
      226, 116, 182, 197, 242, 230, 164, 138, 2, 10, 165, 175, 236, 34, 124, 33, 126, 240, 207, 161,
      211, 50, 136, 184, 165, 168, 33, 187, 35, 184, 198, 52, 251, 14, 217, 188, 249, 68, 18, 96,
      37, 102, 82, 219, 233, 0, 147, 37, 202, 223, 200, 15, 209, 242, 17, 196, 110, 125, 146, 117,
      131, 247, 37, 73, 232, 101, 115,
    ],
    [
      253, 248, 90, 120, 216, 246, 31, 0, 226, 134, 172, 248, 90, 126, 42, 170, 135, 32, 92, 167,
      238, 239, 74, 222, 63, 141, 186, 19, 103, 129, 104, 7, 141, 209, 83, 14, 233, 199, 228, 186,
      51, 243, 154, 131, 20, 88, 62, 110, 0, 98, 166, 179, 48, 30, 251, 158, 104, 43, 186, 6, 182,
      97, 171, 72, 67, 65, 129, 143, 95, 139, 196, 6, 203, 49, 62, 161, 253, 135, 191, 60, 71, 105,
      229, 68, 39, 33, 204, 82, 152, 249, 237, 11, 110, 208, 245, 162, 51, 231, 223, 128, 216, 220,
      79, 180, 181, 244, 249, 43, 191, 147, 246, 133, 174, 247, 236, 154, 73, 231, 146, 248, 194,
      21, 144, 154, 105, 101, 123, 91,
    ],
  ],
  IV_LENGTH: 16,
};
